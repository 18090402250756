import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Loader from "../../sections/loader/Loader";
import toast from "react-hot-toast";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import parse from "html-react-parser";
import imageCompression from "browser-image-compression";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + " .......";
  }
  return text;
};

const stripHtml = (html) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

const Services = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [serviceImage, setServiceImage] = useState(null);
  const [serviceAltText, setServiceAltText] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceMetaTagDescription, setServiceMetaTagDescription] = useState('');
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOpen = (row) => {
    setCurrentRow(row);
    setServiceName(row?.service_name);
    setServiceImage(row?.service_image);
    setServiceAltText(row?.image_alt_text);
    setServiceDescription(row.service_description);
    setServiceMetaTagDescription(row.meta_tag_description);
    setImagePreview(row?.service_image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setImagePreview("");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 1024 * 1024) {
        // Check if file size is greater than 1MB
        toast.error("File size should be less than 1MB");
        return;
      }

      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const base64 = await imageCompression.getDataUrlFromFile(
          compressedFile
        );

        setServiceImage(base64);
        setImagePreview(base64);
      } catch (error) {
        console.error("Error compressing the image:", error);
        toast.error("Error compressing the image");
      }
    }
  };

  const handleSubmit = async () => {
    const newErrors = {};
    if (!serviceName) newErrors.serviceName = "Service name is required";
    if (!serviceAltText)
      newErrors.serviceAltText = "Service alt text is required";
    setErrors(newErrors);
    if (Object.keys(newErrors)?.length === 0) {
      setLoading(true); // Set loading to true when submitting form
      try {
        const payload = {
          service_name: serviceName,
          image: serviceImage,
          service_description: serviceDescription,
          image_alt_text: serviceAltText,
          meta_tag_description: serviceMetaTagDescription
        };

        await axios.put(
          `https://api.smilecloudfamilydental.com/smile-dental/our_services/${currentRow.id}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fetchData();
        handleClose();
        toast.success("Service updated successfully");
      } catch (error) {
        toast.error("Error in updating service");
        console.error("Error submitting the form:", error);
      } finally {
        setLoading(false); // Set loading to false after form submission
      }
    }
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true when fetching data
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/our_services"
      );
      setRows(response.data);
    } catch (error) {
      toast.error("Error fetching the data:", error);
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Service Management</Typography>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Service Name</TableCell>
                <TableCell>Service Image</TableCell>
                <TableCell>Service Image Alt Text</TableCell>
                <TableCell>Service Description</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.service_name}</TableCell>
                  <TableCell>
                    {row.service_image && (
                      <img
                        src={row.service_image}
                        alt={row.service_name}
                        style={{ width: 50, height: 50 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.image_alt_text}</TableCell>
                  <TableCell>
                    {parse(
                      truncateText(stripHtml(row.service_description), 50)
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Service Management</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() => document.getElementById("imageInput").click()}
                style={{ cursor: "pointer" }}
              >
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" height="200" />
                ) : (
                  <Typography>Click to select an image</Typography>
                )}
              </Box>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="service_name"
                label="Service Name"
                type="text"
                fullWidth
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                error={!!errors.serviceName}
                helperText={errors.serviceName}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="serviceAltText"
                label="Service Alt Text"
                type="text"
                fullWidth
                value={serviceAltText}
                onChange={(e) => setServiceAltText(e.target.value)}
                error={!!errors.serviceAltText}
                helperText={errors.serviceAltText}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="serviceMetaTagDescription"
                label="Service Meta Tag Description"
                type="text"
                fullWidth
                value={serviceMetaTagDescription}
                onChange={(e) => setServiceMetaTagDescription(e.target.value)}
                error={!!errors.serviceMetaTagDescription}
                helperText={errors.serviceMetaTagDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SunEditor
                    setDefaultStyle="height: 200px"
                    setContents={serviceDescription}
                    onChange={(e) => setServiceDescription(e)}
                    setOptions={{
                      buttonList: [
                        [
                          "bold",
                          "underline",
                          "italic",
                          "list",
                          "fontSize",
                          "fontColor",
                        ],
                      ],
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Services;
