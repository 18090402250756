import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../sections/loader/Loader";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import parse from "html-react-parser";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";

const truncateText = (text, wordLimit) => {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + " .......";
  }
  return text;
};

const stripHtml = (html) => {
  const tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

function Blogs() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [blogTitle, setBlogTitle] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [blogImageAltText, setBlogImageAltText] = useState("");
  const [blogDescription, setBlogDescription] = useState("");
  const [blogMetaTagDescription, setBlogMetaTagDescription] = useState('');
  const [createdBy, setCreatedBy] = useState("");
  const [errors, setErrors] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    const newErrors = {};
    if (!blogTitle) newErrors.blogTitle = "Blog title is required";
    if (!blogImageAltText)
      newErrors.blogImageAltText = "Blog alt text is required";
    if (!blogDescription)
      newErrors.blogDescription = "Blog description is required";
    if (!createdBy) newErrors.createdBy = "Created By is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("blog_title", blogTitle);
        formData.append("blog_image_alt_text", blogImageAltText);
        formData.append(
          "blog_description",
          blogDescription?.replace(/'/g, "\\'")
        );
        formData.append("created_by", createdBy);
        formData.append("meta_tag_description", blogMetaTagDescription);

        if (imagePreview) {
          formData.append("image", imagePreview);
        }

        if (isEditMode) {
          await axios.put(
            `https://api.smilecloudfamilydental.com/smile-dental/blogs/${selectedRow?.id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } else {
          await axios.post(
            "https://api.smilecloudfamilydental.com/smile-dental/blogs",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        }
        toast.success("Update successfully");
        fetchData();
        handleClose();
      } catch (error) {
        console.error("Error submitting the form:", error);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.delete(
        `https://api.smilecloudfamilydental.com/smile-dental/blogs/${id}`
      );
      fetchData();
      setDeleteConfirmOpen(false);
      toast.success("Deleted successfully");
    } catch (error) {
      console.error("Error deleting the blog:", error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (row = null) => {
    if (row) {
      setSelectedRow(row);
      setBlogTitle(row.blog_title);
      setBlogImageAltText(row.blog_image_alt_text);
      setBlogDescription(row.blog_description);
      setBlogMetaTagDescription(row.meta_tag_description);
      setCreatedBy(row.created_by);
      setImagePreview(row.blog_image);
      setIsEditMode(true);
    } else {
      setSelectedRow(null);
      setBlogTitle("");
      setBlogImageAltText("");
      setBlogDescription("");
      setBlogMetaTagDescription('');
      setCreatedBy("");
      setImagePreview(null);
      setIsEditMode(false);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
    setImagePreview("");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        toast.error("File size should be less than 1MB");
        return;
      }
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        toast.error("Error compressing image");
      }
    }
  };

  const handleDeleteConfirmOpen = (row) => {
    setSelectedRow(row);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirmClose = () => {
    setDeleteConfirmOpen(false);
    setSelectedRow(null);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.smilecloudfamilydental.com/smile-dental/blogs"
      );
      setRows(response?.data);
    } catch (error) {
      toast.error(error.message);
      console.error("Error fetching the data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {loading && <Loader />}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4">Blogs</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Add Blog
        </Button>
      </Stack>

      <Card sx={{ padding: "20px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Blog Title</TableCell>
                <TableCell>Blog Image</TableCell>
                <TableCell>Blog Image Alt Text</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{row.blog_title}</TableCell>
                  <TableCell>
                    {row.blog_image && (
                      <img
                        src={row.blog_image}
                        alt={row.blog_title}
                        style={{ width: 50, height: 50 }}
                      />
                    )}
                  </TableCell>
                  <TableCell>{row.blog_image_alt_text}</TableCell>
                  <TableCell>
                    {parse(truncateText(stripHtml(row.blog_description), 50))}
                  </TableCell>
                  <TableCell>{row.created_by}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteConfirmOpen(row)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{isEditMode ? "Edit Blog" : "Add Blog"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                border="1px dashed grey"
                height={200}
                width="100%"
                onClick={() => document.getElementById("imageInput").click()}
                style={{ cursor: "pointer" }}
              >
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" height="200" />
                ) : (
                  <Typography>Click to select an image</Typography>
                )}
              </Box>
              <input
                type="file"
                id="imageInput"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="blogTitle"
                label="Blog Title"
                type="text"
                fullWidth
                value={blogTitle}
                onChange={(e) => setBlogTitle(e.target.value)}
                error={!!errors.blogTitle}
                helperText={errors.blogTitle}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="blogImageAltText"
                label="Blog Image Alt Text"
                type="text"
                fullWidth
                value={blogImageAltText}
                onChange={(e) => setBlogImageAltText(e.target.value)}
                error={!!errors.blogImageAltText}
                helperText={errors.blogImageAltText}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                margin="dense"
                name="createdBy"
                label="Created By"
                type="text"
                fullWidth
                value={createdBy}
                onChange={(e) => setCreatedBy(e.target.value)}
                error={!!errors.createdBy}
                helperText={errors.createdBy}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="dense"
                name="blogMetaTagDescription"
                label="Blog Meta Tag Description"
                type="text"
                fullWidth
                value={blogMetaTagDescription}
                onChange={(e) => setBlogMetaTagDescription(e.target.value)}
                error={!!errors.blogMetaTagDescription}
                helperText={errors.blogMetaTagDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SunEditor
                    setDefaultStyle="height: 200px"
                    setContents={blogDescription}
                    onChange={(e) => setBlogDescription(e)}
                    setOptions={{
                      buttonList: [
                        [
                          "bold",
                          "underline",
                          "italic",
                          "list",
                          "fontSize",
                          "fontColor",
                        ],
                      ],
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteConfirmOpen} onClose={handleDeleteConfirmClose}>
        <DialogTitle>Delete Blog</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this blog?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteConfirmClose}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedRow?.id)}
            color="secondary"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Blogs;
